import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import MarkdownContent from '../components/MarkdownContent'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

export const AboutPageTemplate = ({ title, content, haccpimage, acoimage }) => (    
  <section id="main">    
    <div className="inner">
      <header className="major special">
        <h1>{title}</h1>
      </header>      
      <MarkdownContent content = {content}/>     
      <section id="three">
        <div className="inner">
          <div className="image fit" style={{margin: '5px'}}>            
            <PreviewCompatibleImage imageInfo={haccpimage} />    
          </div>
          <div className="image fit" style={{margin: '5px'}}>
            <PreviewCompatibleImage imageInfo={acoimage} />
          </div>
        </div>
      </section>
    </div>
  </section>
  )

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  haccpimage: PropTypes.shape({
    alt: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  }), 
  acoimage: PropTypes.shape({
    alt: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  }) 
}

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark  
  return (
    <Layout>
      <AboutPageTemplate        
        title={frontmatter.title}
        content={frontmatter.content}
        haccpimage={frontmatter.haccpimage}
        acoimage={frontmatter.acoimage}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    }),
  }),
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPage {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {      
      frontmatter {
        title
        content
        haccpimage {
          alt
          image{
            childImageSharp {
              fluid(maxWidth: 500, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }          
        }
        acoimage {
          alt
          image{
            childImageSharp {
              fluid(maxWidth: 500, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }          
        }
      }
    }
  }
`
